@import url('https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap');

.title-text {
  font-family: 'Fredoka One', cursive;
  font-size: 50px;
}

.list {
  list-style-type:none;
  padding-bottom: 20px;
}

.sub {
  font-size: 20px;
}

.complete {
  font-family: 'Fredoka One', cursive;
  margin-top: -10px;
  font-weight: lighter;
}


footer {
  color: #1b1c1d;
  position: fixed;
   left: 0;
   bottom: 0;
   width: 100%;
   text-align: center;
   padding-bottom: 10px;
   background-color: #fff;
   z-index: 2;
}

img {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.12);
  border-radius: 20px;
}